.content {
  margin-top: 70px;
}

@media (max-width: 5000px) {
  .content {
    padding-left: 20px;
    flex: 1 1 500px;
    padding-top: 70px;
    padding-left: 290px;
  }
}
@media (max-width: 1500px) {
  .content {
    padding-left: 20px;
    flex: 1 1 500px;
    padding-top: 70px;
  }
}
@media (max-width: 700px) {
  .content {
    flex: 1 1 400px;
    padding-left: 0px;
    padding-top: 70px;
    width: 100vw;
  }
}
@media (max-width: 500px) {
  .content {
    flex: 1 1 250px;
    padding-left: 0px;
    padding-top: 70px;
    width: 100vw;
  }
}