@font-face {
  font-family: "ProximaRegular";
  src: url("fonts/proximanova_regular.ttf") format("truetype");
}
@font-face {
  font-family: "Nullchock";
  src: url("fonts/nulshockrg-bold.ttf") format("truetype");
}
* {
  font-family: "ProximaRegular";
  font-size: 24px;
  line-height: 26px;
  font-weight: 400;
}
* a {
  text-decoration: none;
  color: inherit;
}

html {
  width: 100vw;
}

.btn {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border: 2px solid #fa2424;
  height: 40px;
  font-size: 24px;
  line-height: 20px;
  color: #fa2424;
  font-weight: 600;
  -webkit-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  text-decoration: none;
  background: transparent;
  padding: 0 30px;
  outline: transparent;
  margin-left: 20px;
  width: 196px;
}

.container {
  width: 1500px;
  padding-left: 30px;
  margin-right: 30px;
}

.wrapper {
  max-width: 2000px;
}

.flex_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}