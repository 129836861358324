.pageContainer {
  margin-right: 40px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.pageContainer .flexLeft {
  flex: 1 1 700px;
}
.pageContainer .flexRight {
  flex: 0 0 300px;
}
.pageContainer p {
  font-size: 18px;
  text-align: justify;
}
.pageContainer .headTitle {
  margin-top: 40px;
  font-size: 42px;
  line-height: 40px;
}
@media (min-width: 1200px) {
  .pageContainer .headTitle {
    font-size: 42px;
    line-height: 40px;
  }
}
.pageContainer .titleH2 {
  margin: 20px 0 20px 0;
  font-size: 30px;
  line-height: 30px;
}
@media (min-width: 768px) {
  .pageContainer .title-h2 {
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width: 5000px) {
  .pageContainer .pageContainer {
    max-width: 1220px;
    padding-left: 49px;
    padding-right: 30px;
  }
}
@media (max-width: 1500px) {
  .pageContainer .pageContainer {
    width: calc(100% - 60px);
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 700px) {
  .pageContainer .pageContainer {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 500px) {
  .pageContainer .pageContainer {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.pageContainer .flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.pageContainer .flexRowItems {
  flex: 1 1 30%;
  min-width: 300px;
}

.requisites {
  width: calc(100% - 60px);
  padding-left: 30px;
}
.requisites span {
  font-size: 16px;
}
.requisites img {
  width: 150px;
  height: 150px;
}

.Page404 {
  height: 100%;
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
  transition: all 0.6s;
}
.Page404 .fof {
  display: table-cell;
  vertical-align: middle;
}
.Page404 .fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}
@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

.Abaut {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 30px;
  padding-top: 50px;
}
.AbautImg {
  flex: 0 0 300px;
  height: 400px;
  background-size: 350px;
  background-repeat: no-repeat;
  background-position: -50px;
  background-blend-mode: luminosity;
  opacity: 0.7;
  transform: scale(-1, 1);
}

.ProjectsPage_list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
}
.ProjectsPage_list_item {
  border: 1px solid #888;
  border-radius: 15px;
  padding: 15px;
}
.ProjectsPage_flex {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.ProjectsPage_gitFigma {
  flex: 0 0 50px;
  width: height;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}
.ProjectsPage_gitFigma img {
  height: 100px;
}

@media (max-width: 5000px) {
  .ProjectsPage_list {
    width: calc(100% - 30px);
  }
  .ProjectsPage_list_item {
    flex: 0 0 300px;
  }
  .ProjectsPage_title {
    margin-top: 10px;
  }
  .ProjectsPage_title a {
    font-size: 32px;
    font-weight: 600;
  }
  .ProjectsPage_img {
    height: 300px;
    width: 400px;
    background-size: cover;
  }
}
@media (min-width: 700px) {
  .ProjectsPage_list {
    padding-left: 30px;
    width: calc(100% - 60px);
  }
  .ProjectsPage_list_item {
    flex: 0 0 300px;
  }
  .ProjectsPage_title {
    margin-top: 10px;
  }
  .ProjectsPage_title a {
    font-size: 32px;
    font-weight: 600;
  }
  .ProjectsPage_promo {
    flex: 0 0 400px;
    margin: 0 auto;
  }
  .ProjectsPage_text {
    flex: 1 0 400px;
    padding: 30px;
    text-align: justify;
  }
}
@media (max-width: 700px) {
  .ProjectsPage_list {
    width: calc(100% - 30px);
  }
  .ProjectsPage_list_item {
    flex: 0 0 300px;
  }
  .ProjectsPage_title {
    margin-top: 10px;
  }
  .ProjectsPage_title a {
    font-size: 20px;
    font-weight: 600;
  }
  .ProjectsPage_promo {
    flex: 0 0 400px;
    margin: 0 auto;
  }
  .ProjectsPage_gitFigma img {
    height: 30px;
    margin: 0 20px;
  }
}
@media (max-width: 500px) {
  .ProjectsPage_list {
    padding-left: 7px;
    width: calc(100% - 15px);
  }
  .ProjectsPage_list_item {
    flex: 0 0 250px;
  }
  .ProjectsPage_title {
    margin-top: 10px;
  }
  .ProjectsPage_title a {
    font-size: 20px;
    font-weight: 600;
  }
  .ProjectsPage_promo {
    flex: 0 0 250px;
    margin: 0 auto;
  }
  .ProjectsPage_img {
    height: 150px;
    width: 200px;
    background-size: cover;
  }
  .ProjectsPage_text {
    flex: 1 0 200px;
    padding: 30px;
    text-align: justify;
    font-size: 15px;
  }
  .ProjectsPage_text div {
    font-size: 15px;
  }
}